import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";

import axios from "axios";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import jwtDecode from "jwt-decode";
import * as Constants from "../constants";
import { login, setLocale, setAvatar, setCustomToken, clearToken, resetUsername } from "../slices/auth";

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const username = useSelector((state) => state.auth.username);
    const isAuthenticated = username !== null;
    let token = window.localStorage.getItem("c_token");

    function redirect() {
        if (location.state === null) {
            navigate("/");
        } else if (location.state.from !== location.pathname && location.state.from !== undefined) {
            navigate(location.state.from);
        } else {
            navigate("/");
        }
    }

    const onGoogleLoginSuccess = async (googleRes) => {
        const jwt = jwtDecode(googleRes.credential);
        dispatch(setAvatar(jwt.picture));

        // code en prod différent de en dev merci :-\
        // dispatch(login({ credential: "local token", isGoogle: false }))
        dispatch(login({ credential: googleRes.credential, isGoogle: true }))
            .unwrap()
            .then((userData) => {
                dispatch(setCustomToken(userData.token));
                dispatch(setLocale(userData.language.code));
                i18n.changeLanguage(userData.language.code);
                redirect();
            })
            .catch((error) => {
                toast.warning("login failed");
                if (error.code === "ECONNABORTED") {
                    toast.warning("Timeout error, check your internet and or vpn connection.");
                } else if (error.response.status === 401) {
                    navigate("/login");
                } else {
                    toast.warning("Unauthorized");
                }
            });
    };

    useEffect(() => {
        if (token && !isAuthenticated) {
            let jwt = null;
            try {
                jwt = jwtDecode(token);
                const expDate = jwt.exp;
                const now = Math.floor(Date.now() / 1000) - 10;
                if (now >= expDate) {
                    token = null;
                    window.localStorage.removeItem("c_token");
                    navigate("/login");
                }
            } catch (e) {
                token = null;
                window.localStorage.removeItem("c_token");
                navigate("/login");
            }
            if (token) {
                dispatch(login({ credential: token, isGoogle: false }))
                    .unwrap()
                    .then((userData) => {
                        dispatch(setCustomToken(userData.token));
                        dispatch(setAvatar(jwt.picture));
                        dispatch(setLocale(userData.language.code));
                        i18n.changeLanguage(userData.language.code);
                        redirect();
                    })
                    .catch((er) => {
                        if (parseInt(er.message, 10) === 401) {
                            dispatch(clearToken());
                            dispatch(resetUsername());
                            navigate("/login");
                        }
                        toast.warning("login failed");
                    });
            }
        }
    }, [username, token]);

    return (
        <div>
            {!token && (
                <Container component="main" maxWidth="xs">
                    <Box
                        sx={{
                            marginTop: 8,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <Typography component="h2" variant="h2">
                            Jan Admin
                        </Typography>
                        <Avatar sx={{ m: 1, bgcolor: "primary.dark" }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Box component="form" noValidate sx={{ mt: 10 }}>
                            <GoogleLogin
                                onSuccess={onGoogleLoginSuccess}
                                onError={(res) => {
                                    console.log("Login Failed", res);
                                }}
                                text="signin"
                                shape="circle"
                            />
                        </Box>
                    </Box>
                </Container>
            )}
        </div>
    );
};

export default Login;

// export default connect(null, { signIn, setAvatar, setGoogleToken })(Login);
